<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Tryout berdasrkan Nama...'"
          :onInput="onSearch"
        />

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-voucher
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah</span>
        </b-button>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        :items="data"
        :fields="tableColumns"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Data tidak ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="setEdit(data.item.id)"
              v-b-modal.modal-voucher
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setSelected(data.item.id)"
              v-b-modal.hapus-voucher
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <!-- <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="last_page"
          :total="total"
        />
      </div> -->
    </b-card>

    <b-modal
      id="hapus-voucher"
      cancel-variant="outline-secondary"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-title="Close"
      centered
      title="Hapus Voucher"
      @ok="deleteVoucher"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Produk ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-voucher"
      size="md"
      centered
      :title="isEdit ? 'Edit Voucher' : 'Tambah Voucher'"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : isEdit ? 'Simpan' : 'Tambah'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="isEdit ? fetchDetail() : resetModal()"
      @hidden="resetModal"
      @ok="voucherAction"
    >
      <b-form>
        <b-form-group label="Nama Voucher" label-for="name">
          <b-form-input
            v-model="form.name"
            id="name"
            placeholder="Masukkan Nama Voucher"
          />
        </b-form-group>

        <b-form-group label="Deskripsi" label-for="description">
          <b-form-input
            v-model="form.description"
            id="description"
            placeholder="Masukkan Deksripsi Voucher"
          />
        </b-form-group>

        <b-form-group label="Code" label-for="code">
          <b-form-input
            v-model="form.code"
            id="code"
            placeholder="Masukkan Code"
          />
        </b-form-group>

        <b-form-group label="Jumlah Potongan" label-for="amount">
          <b-form-input
            v-model="form.amount"
            id="amount"
            placeholder="Masukkan Jumlah"
            type="number"
          />
        </b-form-group>

        <b-form-group label="Limit Penggunaan (optional)" label-for="total">
          <b-form-input
            v-model="form.total"
            id="total"
            placeholder="Masukkan Limit"
            type="number"
          />
          <p class="text-secondary tw-text-xs">
            *kosongkan apabila voucher dapat digunakan tanpa batas
          </p>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import repository from "@repofactory";


import SearchInput from '@/@core/components/base/SearchInput.vue';
import Pagination from '@/@core/components/base/Pagination.vue';
import { formatDate, rupiah, useRouter } from "@core/utils/utils";


const repoVoucher = repository.get("VoucherRepository");

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BModal,
        BFormCheckbox,
        VBModal,
        BForm,
        BFormGroup,
        BCardText,
        BDropdown,
        BDropdownItem,
        ToastificationContent,
        BListGroupItem,
        vSelect,
        SearchInput,
        Pagination,
        BDropdown,
        BDropdownItem,
        FeatherIcon
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    data() {
        return {
            isEdit: false,
            isLoading: false,
            search: "",
            data: [],
            from: 0,
            to: 0,
            total: 0,
            last_page: 0,
            tableColumns: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "name",
                    label: "Nama Voucher",
                    formatter: (value) => {
                        return (value || "").toUpperCase()
                    }
                },
                {
                    key: "description",
                    label: "Description",
                },
                {
                    key: "code",
                    label: "Code",
                },
                {
                    key: "amount",
                    label: "Jumlah",
                    formatter: (value) => {
                        return rupiah(value)
                    }
                },
                {
                    key: "created_at",
                    label: "Dibuat pada",
                    formatter: (value) => {
                        return formatDate(value)
                    }
                },
                {
                    key: "updated_at",
                    label: "Diperbarui pada",
                    formatter: (value) => {
                        return formatDate(value)
                    }
                },
                { key: "actions" },
            ],
            selectedId: null,
            isLoadingSubmit: false,
            initialForm : {
              name: null,
              amount: null,
              description: null,
              type: "",
              code: null,
              total: null
            },
            form: {
              ...this.initialForm
            }
        }
    },
    methods: {
        showToast(title, text, variant, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    text,
                    variant,
                    icon,
                },
            });
        },
        onSearch(e) {
            console.log(e.currentTarget.value)
            this.search = e.currentTarget.value
        },
        fetchData() {
            this.isLoading = true
            // const page = this.$route.params.page || 1
            repoVoucher.get(1, this.search).then(res => {
                this.from = res.data.data.from
                this.to = res.data.data.to
                this.last_page = res.data.data.last_page
                this.total = res.data.data.total
                this.data = res.data.data.data
            }).catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
            }).finally(() => this.isLoading = false);
        },

        fetchDetail() {
            repoVoucher.detail(this.selectedId).then(res => {
                this.form = {
                  name: res.data.data.name,
                  description: res.data.data.description,
                  amount: res.data.data.amount,
                  total: res.data.data.total,
                  code: res.data.data.code
                }
            }).catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
            }).finally(() => this.isLoading = false);
        },
        debounce(func) {
            clearTimeout(this.debounceTimeout); // Clear the previous timeout
            this.debounceTimeout = setTimeout(func, 500); // Set a new timeout
        },
        setSelected(value) {
          this.selectedId = value
        },
        setEdit(value) {
          this.selectedId = value
          this.isEdit = true
        },
        deleteVoucher() {
          this.isLoadingSubmit = true
          repoVoucher.delete(this.selectedId).then(() => {
            this.fetchData()
          }).catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
            }).finally(() => this.isLoadingSubmit = false);
        },
        voucherAction(bvModalEvent) {
          if (this.isEdit) {
            this.editVoucher(bvModalEvent);
          } else {
            this.addVoucher(bvModalEvent);
          }
        },

        addVoucher(bvModalEvent) {
          bvModalEvent.preventDefault();
          this.isLoadingSubmit = true
          repoVoucher.create({
            ...this.form,
            total: this.form.total ? this.form.total : 0
          }).then(() => {
            this.fetchData()
            bvModalEvent.vueTarget.hide()
          }).catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
            }).finally(() => this.isLoadingSubmit = false);
        },

        editVoucher(bvModalEvent) {
          bvModalEvent.preventDefault();
          this.isLoadingSubmit = true
          repoVoucher.update(this.selectedId, {
            ...this.form,
            total: this.form.total ? this.form.total : 0
          }).then(() => {
            this.fetchData()
            this.isEdit = false
            this.selectedId = null
            bvModalEvent.vueTarget.hide()
          }).catch((error) => {
              if (error.response) {
                this.showToast(
                  "Error",
                  error.response.data.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              } else if (error.request) {
                this.showToast(
                  "Error",
                  "Tidak bisa request data ke server",
                  "danger",
                  "AlertTriangleIcon"
                );
              } else {
                this.showToast(
                  "Error",
                  error.message,
                  "danger",
                  "AlertTriangleIcon"
                );
              }
            }).finally(() => this.isLoadingSubmit = false);
        },

        resetModal() {
          this.form = {...this.initialForm}
        }
    },
    mounted() {
        this.fetchData()
    },
    watch: {
        search() {
            this.debounce(() => {
                this.fetchData();
            }, 500);
        },
    },

}
</script>
